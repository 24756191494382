<template>
    <section class="catalog" id="productCatalog">
        <div class="container">
            <p class="section-title">{{ title }}</p>
            <div class="catalog-filter" v-if="filters !== null">
                <div class="catalog-filter-row">
                    <div class="catalog-filter-block">
                        <div class="catalog-filter-block-title">Бренд</div>
                        <div class="catalog-filter-block-buttons">
                            <!--<div class="catalog-filter-block-buttons-button">
                                <input id="brand1" type="radio" name="filter[brand]" style="display: none" value="all" />
                                <label for="brand1" class="&#45;&#45;button &#45;&#45;switch_button">
                                    Все</label>
                            </div>-->
                            <button @click="setFilter('brend', 'all')"
                                    :class="{'--active': filter.brend === 'all'}"
                                    data-filter_brend="all" type="button" class="--button --switch_button">Все
                            </button>

                            <button v-for="(option, idx) in filters.brend.options"
                                    :disabled="checkEnabled('brend',option)"
                                    @click="setFilter('brend', option)" data-filter_brend="all"
                                    :class="{'--active': checkActive('brend', option)}"
                                    type="button" class="--button --switch_button --switch_button_work">{{ option }}
                            </button>
                        </div>
                    </div>
                    <div style="max-width: 768px" class="catalog-filter-block">
                        <div class="catalog-filter-block-title">Серии</div>
                        <div class="catalog-filter-block-buttons">
                            <button @click="setFilter('seriia', 'all')"
                                    :class="{'--active': filter.seriia === 'all'}"
                                    type="button" class="--button --switch_button">Все
                            </button>

                            <button v-for="(option, idx) in filters.seriia.options"
                                    :disabled="checkEnabled('seriia',option)"
                                    :class="{'--active': checkActive('seriia', option)}"
                                    @click="setFilter('seriia', option)" data-filter_brend="all"
                                    type="button" class="--button --switch_button --switch_button_work">{{ option }}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="catalog-filter-row">
                    <div class="catalog-filter-block">
                        <div class="catalog-filter-block-title">Класс</div>
                        <div class="catalog-filter-block-buttons">
                            <button @click="setFilter('klass', 'all')"
                                    :class="{'--active': checkActive('klass', 'all')}"
                                    type="button" class="--button --switch_button">Все
                            </button>

                            <button v-for="(option, idx) in filters.klass.options"
                                    :disabled="checkEnabled('klass',option)"
                                    :class="{'--active': checkActive('klass', option)}"
                                    @click="setFilter('klass', option)" data-filter_brend="all"
                                    type="button" class="--button --switch_button --switch_button_work">{{ option }}
                            </button>
                        </div>
                    </div>
                    <div class="catalog-filter-block">
                        <div class="catalog-filter-block-title">Объём двигателя</div>
                        <div class="catalog-filter-block-buttons">

                            <button @click="setFilter('obieem_dvigatelia', 'all')" data-filter_brend="all"
                                    :class="{'--active':  checkActive('obieem_dvigatelia', 'all')}"
                                    type="button" class="--button --switch_button">Все
                            </button>

                            <button v-for="(option, idx) in filters.obieem_dvigatelia.options"
                                    :disabled="checkEnabled('obieem_dvigatelia',option)"
                                    :class="{'--active': checkActive('obieem_dvigatelia', option)}"
                                    @click="setFilter('obieem_dvigatelia', option)" data-filter_brend="all"
                                    type="button" class="--button --switch_button --switch_button_work">{{ option }}
                            </button>
                        </div>
                    </div>
                    <div class="catalog-filter-block">
                        <div class="catalog-filter-block-title">Цена</div>
                        <div class="catalog-filter-block-buttons">
                            <button type="button" class="--button --switch_button"
                                    @click="setPriceSort('ASC')"
                                    :class="{'--active': priceSort === 'ASC'}">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_230_12640)">
                                        <path
                                            d="M10.6689 6.276L4.93095 12.014L3.98828 11.0713L9.72561 5.33333H4.66895V4H12.0023V11.3333H10.6689V6.276Z"
                                            fill="#263238"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_230_12640">
                                            <rect width="16" height="16" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                            <button type="button" class="--button --switch_button"
                                    @click="setPriceSort('DESC')"
                                    :class="{'--active': priceSort === 'DESC'}">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_230_12642)">
                                        <path
                                            d="M9.72695 10.6693L3.98828 4.93133L4.93095 3.98866L10.6689 9.726V4.66933H12.0023V12.0027H4.66895V10.6693H9.72695Z"
                                            fill="#263238"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_230_12642">
                                            <rect width="16" height="16" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="catalog-filter-row" v-if="filter.kolicestvo_taktov && filter.kolicestvo_taktov !=='all'">
                    <div class="catalog-filter-block">
                        <div class="catalog-filter-block-title">Двигатель</div>
                        <div class="catalog-filter-block-buttons">
                            <button @click="setFilter('kolicestvo_taktov', 'all')"
                                    :class="{'--active': checkActive('kolicestvo_taktov', 'all')}"
                                    type="button" class="--button --switch_button">Все
                            </button>

                            <button v-for="(option, idx) in filters.kolicestvo_taktov.options"
                                    :disabled="checkEnabled('kolicestvo_taktov',option)"
                                    :class="{'--active': checkActive('kolicestvo_taktov', option)}"
                                    @click="setFilter('kolicestvo_taktov', option)" data-filter_brend="all"
                                    type="button" class="--button --switch_button">{{ option }}
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="catalog-products">
                <div class="row w-100">

                    <div v-for="(product, idx) in products" v-if="products !== null && products.length"
                         class="col-md-4 d-flex align-items-stretch catalog-product-card">
                        <div class="catalog-product w-100">
                            <div class="catalog-product-image"
                                 :style="'background-image: url(/storage/content/'+product.card_image+')'"></div>
                            <div class="catalog-product-title">{{ product.title }}</div>
                            <div class="catalog-product-data-block">
                                <div class="catalog-product-spec">
                                    <div class="catalog-product-spec-row">
                                        <span>Артикул</span>
                                        <span>{{ product.article }}</span>
                                    </div>
                                    <div v-for="(attr, aIdx) in product.attributes" class="catalog-product-spec-row">
                                        <span>{{ attr.name }}</span>
                                        <span>{{ attr.pivot.value }}</span>
                                    </div>
                                </div>
                                <div class="catalog-product-price">
                                    <span class="--old"> {{ oldPrice(product) }} </span> {{
                                        formatPrice(product.price)
                                    }}
                                </div>
                                <div class="catalog-product-buttons">
                                    <a :href="'/description/'+product.slug" class="--button --transparent_button">ПеРЕЙТИ
                                        НА СТРАНИЦУ
                                        СНЕГОХОДА</a>
                                    <button type="button" @click="buyClick(idx)" class="--button --base_button">Заказать
                                        со скидкой 30%
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="--loading" v-if="loading === true">
                        Идёт загрузка...
                    </div>
                    <div class="--not_found" v-if="loading !== true && products.length === 0">
                        Не удалось найти модели по заданным параметрам, попробуйте изменить их.
                    </div>

                </div>
                <button type="button" v-if="current < total"
                        @click="loadProducts(parseInt(current)+1)"
                        class="catalog-products-load-more --button --transparent_button">
                    Показать ещё
                </button>
            </div>
        </div>

        <div class="modal buy-modal" tabindex="-1" id="buyModal">
            <div class="modal-dialog">
                <div class="modal-content" style="max-width: 365px">
                    <div class="modal-body" v-if="selectedModel !== null">
                        <!--                        <div class="modal-body-image"
                                                     style="background-image: url('./images/modal_1.png')">

                                                </div>-->
                        <div class="modal-body-form">
                            <button type="button" data-bs-dismiss="modal" class="--close-button"></button>
                            <div class="--form-content" v-if="send === false">
                                <div class="modal-body-form-title" style="line-height: 110%;">Заказать снегоход
                                    {{ products[selectedModel].title }}
                                </div>
                                <div
                                    v-if="selectedModel !== null"
                                    style="    width: 100%;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;"
                                    :style="'background-image: url(/storage/content/'+products[selectedModel].card_image+')'"
                                    class="modal-body-form-image">

                                </div>
                                <div class="modal-body-form-text">Оставьте номер телефона, и наш менеджер свяжется
                                    с вами.
                                </div>
                                <form v-on:submit="sendForm" id="buyModalForm">
                                    <input name="phone" type="text" required placeholder="+7" v-model="form.phone" class="--input">
                                    <span class="--color-text" v-if="error">{{ error }}</span>
                                    <input name="product_id" type="hidden" v-model="form.product_id" class="--input">
                                    <button type="submit" class="--button --base_button">Жду звонка</button>
                                </form>
                                <p class="--subtext">Нажимая кнопку, вы даёте согласие на обработку <a
                                    href="#">персональных
                                    данных</a>, а также принимаете<a href="#">пользовательское соглашение</a></p>
                            </div>
                            <div class="--send-content" v-if="send === true">
                                <div class="--send-content-icon success"></div>
                                <div class="--send-content-message">ЗАявка принята</div>
                            </div>

                            <button type="button" data-bs-dismiss="modal" class="--continue">Продолжить покупки</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "SnegCatalog",
    props: ['filter_params', 'title', 'limit'],
    setup() {

    },
    data() {
        return {
            total: 0,
            current: 1,
            products: [],
            filters: null,
            activeFilters: null,
            filter: {},
            modal: null,
            selectedModel: null,
            form: {
                phone: '',
                product_id: null
            },
            send: false,
            error: false,
            loading: false,
            priceSort: 'ASC'
        }
    },
    methods: {
        oldPrice(product) {
            if (parseInt(product.discount) > 0)
                return this.formatPrice(parseFloat(product.price) / (100 - parseInt(product.discount)) * 100)
            else
                return this.formatPrice(parseFloat(product.price) / 0.7)
        },
        setPriceSort(method) {
            this.priceSort = method;
        },
        formatPrice(price) {
            return new Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency: 'RUB',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(
                price,
            )
        },
        loaderFilters() {
            const vm = this;
            axios.post('/system/get-filters', {})
                .then(response => response.data)
                .then((data) => {
                    for (let [key, obj] of Object.entries(data)) {
                        if (!vm.filter.hasOwnProperty(key)) {
                            Vue.set(vm.filter, key, 'all');
                        }
                        //vm.filter[key] = 'all';
                    }
                    vm.filters = data;
                    vm.activeFilters = data;
                    vm.getActiveFilters();
                    vm.$watch('filter', vm.watchFilter, {deep: true})
                    vm.loadProducts(vm.current);
                })
        },
        getActiveFilters() {
            const vm = this;
            axios.post('/system/get-filters', {filter: this.filter})
                .then(response => response.data)
                .then((data) => {
                    vm.activeFilters = data;
                })
        },
        loadProducts(page) {
            const vm = this;
            //this.current = page;
            this.loading = true;
            axios.post('/system/get-products/' + page, {filter: this.filter, sort: this.priceSort, limit: this.limit})
                .then(response => response.data)
                .then((data) => {
                    vm.current = parseInt(data.page);
                    vm.total = parseInt(data.pages);
                    vm.loading = false;
                    for (let [key, obj] of Object.entries(data.products)) {
                        vm.products.push(obj);

                    }

                    //vm.products.push(data.products);
                })
        },
        setFilter(filter, value) {
            //this.filter[filter] = value;

            if (this.filter[filter] === value)
                this.filter[filter] = 'all';
            else
                Vue.set(this.filter, filter, value);

            this.getActiveFilters();
        },
        buyClick(idx) {
            this.form.product_id = this.products[idx].id;
            this.selectedModel = idx;
            if (this.modal !== null) {
                this.modal.show();


            } else
                alert('Возникла ошибка...')
        },
        sendForm($event) {
            const vm = this;
            vm.error = false;
            $event.preventDefault();
            axios.post('/system/send-form', this.form)
                .then(response => response.data)
                .then((data) => {
                    if (data.status === 'success') {
                        vm.send = true;
                        vm.error = false;
                    } else
                        vm.error = data.message;
                })
        },
        checkActive(filter, value) {
            if (typeof this.filter[filter] === 'object') {
                return Array.prototype.includes.call(this.filter[filter], value)
            } else
                return this.filter[filter] === value;
        },
        checkEnabled(filter, value) {
            /*if (typeof this.activeFilters[filter] === 'object') {
                return Array.prototype.includes.call(this.activeFilters[filter], value)
            } else
                return this.activeFilters[filter] === value;*/
            for (let [key, obj] of Object.entries(this.filters[filter].options)) {

                if (Array.prototype.includes.call(this.activeFilters[filter].options, value))
                    return false;
            }
            return true;
        },
        watchFilter() {
            this.products = [];
            this.getActiveFilters();
            this.loadProducts(1);
        }
    },
    mounted() {
        const vm = this;
        this.filter = JSON.parse(JSON.stringify(this.filter_params));
        this.loaderFilters();
        //this.loadProducts(this.current);
        let modalElement = document.getElementById('buyModal');
        if (modalElement) {
            this.modal = new bootstrap.Modal(document.getElementById('buyModal'), {
                //backdrop: "static"
                //keyboard: false
            });
            modalElement.addEventListener('hidden.bs.modal', function (event) {
                vm.send = false;

                /*                vm.form = {
                    phone: '',
                    product_id: null
                };*/

                vm.form.phone = '';
                vm.form.product_id = null;

                vm.selectedModel = null;
            })
        }

        let dopInfo = document.getElementById('dop_info-tpl');
        if (dopInfo) {
            let inputs = dopInfo.querySelectorAll('[data-get_param_name]');
            let getParams = {};
            inputs.forEach((elem) => {
                getParams[elem.getAttribute('data-get_param_name')] = elem.value;
            })
            vm.form['get_params'] = getParams;
        }
    },
    computed: {},
    watch: {
        priceSort() {

            this.products = [];
            this.loadProducts(1);
        },
        /* filter: {
             handler(val, oldVal) {
                 this.products = [];
                 this.getActiveFilters();
                 this.loadProducts(1);
             },
             deep: true
         },*/
        'filter.klass': {
            handler(val, oldVal) {
                if (typeof oldVal !== "undefined" && val !== oldVal) {
                    Vue.set(this.filter, 'obieem_dvigatelia', 'all');
                    this.getActiveFilters();
                }
                //this.filter.obieem_dvigatelia = 'all';
                //this.getActiveFilters();
            }
        },
        'filter.seriia': {
            handler(val, oldVal) {
                if (typeof oldVal !== "undefined" && val !== oldVal) {
                    //this.filter.klass = 'all';
                    //this.filter.obieem_dvigatelia = 'all';
                    Vue.set(this.filter, 'klass', 'all');
                    Vue.set(this.filter, 'obieem_dvigatelia', 'all');
                    this.getActiveFilters();
                }
                //this.getActiveFilters();
            }
        },
        'filter.brend': {
            handler(val, oldVal) {
                if (typeof oldVal !== "undefined" && val !== oldVal) {
                    //this.filter.seriia = 'all';
                    // this.filter.klass = 'all';
                    //this.filter.obieem_dvigatelia = 'all';

                    Vue.set(this.filter, 'seriia', 'all');
                    Vue.set(this.filter, 'klass', 'all');
                    Vue.set(this.filter, 'obieem_dvigatelia', 'all');
                    this.getActiveFilters();
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.--loading, .--not_found {
    text-align: center;
    font-size: 32px;
    padding: 32px 0;
}

.--switch_button_work.--active {
    position: relative;

}

.--switch_button_work.--active:after {
    content: " ";
    background-image: url('data:image/svg+xml,<svg height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 26 26" xml:space="preserve"><g><path style="fill:%23F4511E;" d="M21.125,0H4.875C2.182,0,0,2.182,0,4.875v16.25C0,23.818,2.182,26,4.875,26h16.25C23.818,26,26,23.818,26,21.125V4.875C26,2.182,23.818,0,21.125,0z M18.78,17.394l-1.388,1.387c-0.254,0.255-0.67,0.255-0.924,0L13,15.313L9.533,18.78c-0.255,0.255-0.67,0.255-0.925-0.002L7.22,17.394c-0.253-0.256-0.253-0.669,0-0.926l3.468-3.467L7.221,9.534c-0.254-0.256-0.254-0.672,0-0.925l1.388-1.388c0.255-0.257,0.671-0.257,0.925,0L13,10.689l3.468-3.468c0.255-0.257,0.671-0.257,0.924,0l1.388,1.386c0.254,0.255,0.254,0.671,0.001,0.927l-3.468,3.467l3.468,3.467C19.033,16.725,19.033,17.138,18.78,17.394z"/></g></svg>');
    font-size: 0;
    position: absolute;
    top: -10px;
    background-size: contain;
    /* background-color: white; */
    border-radius: 100%;
    border-color: var(--deep-orange-600);
    border: solid 1px var(--deep-orange-600);
    line-height: 100%;
    padding: 0;
    cursor: pointer;
    color: var(--deep-orange-600);
    width: 20px;
    height: 20px;
    background-color: white;
}
</style>
